a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}




$primary-font: 'Segoe UI', Arial, Helvetica, sans-serif;

$primary-color: #25A0DA;
$primary-color-light: #2ABDFF;
$primary-color-dark: #1B6D95;
$button-color: $primary-color;



/* Enforce on all components */
* {
  font-family: $primary-font;
}

.datepicker-actions > * {
  font: unset !important;
}

.ui-progressbar .ui-progressbar-value-animate {
  transition: width 0.1s ease-in-out;
}

date-picker {
  .datepicker-actions__input {
    height: 26px !important;
  }

  &.ng-invalid .datepicker-actions__input {
    border: 1px solid red;
  }
}

.input-group-addon {
  padding: 0.0rem 0.75rem;
}
// DYNAMSOFT OVERRIDES
.dynamsoft-dwt-dialogProgress {
  display: none;
}

.ui-progressbar .ui-progressbar-label {
  line-height: 100%;
}

.dynamsoft-dwt-container-box {
  > div {
    border: none !important;
  }
}
// GENERIC
.app-header {
  flex: unset;
}
.app-footer {
  min-height: 25px;
  line-height: 25px;
  flex: 0 0 25px;
}

.app-header.navbar .navbar-brand {
  background-size: 120px auto;
}


.navbar {
  flex-wrap: unset;
}

app-main {
  min-width: 100vw;
}

.main {
  background-color: #e4e5e6;
  height: 100vh;
}

.fa {

  &.clickable {
    border: 1px solid lightgray;
    border-radius: 4px;
    cursor: pointer;
  }
}

.abtn {
  padding: 0 10px 0 4px;
  border-radius: 4px;
  border: 1px solid gray;
  background-color: white;

  i {
    color: #333333;
  }

  &.center {
    padding: 4px;
    min-width: 40px;
  }

  &:hover {
    background-color: #EEEEEE;
  }
}

// SCAN-STATION
.form-control {
  -webkit-appearance: menulist;
  height: 26px !important;
  width: auto;
  min-width: 100px;
  max-width: 260px;
  padding: 0 8px;
  display: inline-block;
  color: #333333;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;

  &[disabled] {
    cursor: default;
  }

  &.ng-invalid,
  &.error {
    border: 1px solid red !important;
  }
}

.scan-station {
  width: 1320px;
  height: 670px;
  border: 1px solid gray;
  border-radius: 4px;
  background-color: #FFFFFF;
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.scan-station-header {
  width: 100%;
  height: 20px;
  display: flex;
  //border: 1px solid red;
}

.scan-station-steps {
  width: 623px;
  height: 600px;
  border: 1px solid lightgray;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;


  input:disabled, select:disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
  }

  .batch-load {
    width: 100%;
    margin: 10%;
  }

  .batch-lookup {
    border: 1px solid lightgray;
    border-radius: 4px;
    height: 26px;
    min-height: 26px;
    margin-left: 9px;
    line-height: 26px;
    cursor: pointer;
    padding: 3px 5px 3px 5px;
    background-color: white;
  }

  .form-table {
    margin: auto;
    min-width: 250px;
    margin: 10px;
    float: left;

    .form-control {
      max-width: 245px;
      font-size: 12px;
    }

    td {
      padding: 4px 2px 4px 2px;
      vertical-align: middle;
    }
  }

  .scan-details {
    // z-index: 1000000;
    margin: 0 15px 0 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    position: relative;

    table {
      width: 50%;
      margin: auto;
      background-color: white;
      border-radius: 4px;

      td {
        padding-left: 8px;
      }

      label {
        margin: 0;
        padding: 0;
      }
    }

    label {
      font-weight: 500;
    }

    .qa-button {
      position: absolute;
      right: 68px;
      bottom: 0px;
      font-size: 26px;
      padding: 0;
      height: 40px;
      width: 40px;
    }
  }

  .scan-upload {
    margin: 0 15px 0 15px;
    width: 100%;
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    position: relative;

    h5 {
      line-height: 50px;
    }

    .upload-button {
      position: absolute;
      right: 68px;
      bottom: 15px;
      font-size: 26px;
      padding: 0;
      height: 40px;
      width: 40px;
    }
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }
}

.scan-station-images {
  width: 623px;
  height: 600px;
  border: 1px solid lightgray;
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;

  .image-functions {
    width: 50px;
    height: 550px;
    border-right: 1px solid lightgray;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;

    i {
      width: 50px;
      height: 50px;
      border-bottom: 1px solid lightgray;
      font-size: 30px;
      padding: 0;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      color: #20a8d8;
      background-color: white;
    }

    i:hover {
      background-color: #20a8d8;
      color: white;
    }
  }

  .page-functions {
    width: 100%;
    height: 50px;
    background: #EEEEEE;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;

    button {
      border-radius: 4px;
      border: 1px solid lightgray;
      background: white;
    }

    input {
      border-radius: 4px;
      border: 1px solid lightgray;
      text-align: center;
    }

    .left-control {
      margin: 6px;
    }

    .right-control {
      margin: 6px;
    }
  }
}

.batch-extras {
  position: relative;
  width: 210px;
  height: 250px;
  margin: 10px;
  float: left;

  table {
    min-height: 50px;
    display: block;
  }

  .form-control {
    min-width: 40px;
  }

  .scan-button {
    position: absolute;
    bottom: 0px;
    right: 60px;
    width: 40px;
    height: 40px;
    font-size: 26px;
    padding: 0;
    text-align: center;
    line-height: 26px;
  }

  .reject-button {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    font-size: 26px;
    padding: 0;
    text-align: center;
    line-height: 26px;

    i {
      color: red;
    }
  }

  .load-button {
    position: absolute;
    bottom: 0px;
    left: 55px;
    width: 40px;
    height: 40px;
    font-size: 26px;
    padding: 0;
    text-align: center;
    line-height: 26px;
  }

  .pdf-password-input {
    position: absolute;
    bottom: 0px;
    left: -32px;
    width: 80px;
    font-size: 26px;
    padding: 0;
    text-align: center;
    line-height: 26px;
    font-size: 11px;
  }
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  padding-right: 15px;
  min-width: 100px;
  font-size: 13px;
}

.ui-growl {
  > * {
    color: #333333;
  }

  .fa {
    color: #333333;
  }
}

.ui-growl-image {
  display: none;
}

.ui-button-icon-only {
  text-indent: unset;
}

.ui-button:hover {
  background: $primary-color !important;

  :hover {
    background: $primary-color !important;
  }
}





.scan-history-table {
  width: 100%;
  border: 1px solid #CCCCCC;

  tr {
    border-bottom: 1px solid lightgray;
  }

  td {
    text-align: center;
    border: 1px solid #CCCCCC;

    button {
      margin: 4px 8px 4px 8px;
    }
  }

  thead {
    border-bottom: 1px solid lightgray;

    tr {
      td {
        background-color: #333333;
        color: #FFFFFF;
      }
    }
  }

  tr:nth-of-type(odd) {
    background-color: #EEEEEE;
  }
}

.reportwrapper {
  background-color: #DDDDDD;
  margin: 10px;
  padding: 10px;
  border: 1px solid #CCCCCC;
  display: block;
  width: 525px;
  height: auto;
}



div.ProgressUiGrid {
  position: absolute;
  top: 50%;
  left: 50%;
}
